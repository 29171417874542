import React, { Component } from 'react'

export default class PersonalInfo extends Component {
  render() {
    return (
      <div>
        <p>I am a musician, programmer, writer, performer, and many other roles across a number of different interests and areas of expertise.</p>
      </div>
    )
  }
}
