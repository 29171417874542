import React, { Component } from 'react'
import '../../stylesheets/luella.sass'


export default class Luella extends Component {
  render() {
    return (
      <div className='luella-bg'>
        <div className='main-content'>
          <h1>Luella</h1>
          <p>Coming soon!</p>
        </div>
      </div>
    )
  }
}
